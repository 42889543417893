import React, { useState } from "react"
import { FaAngleDoubleRight } from "react-icons/fa"
import { graphql, useStaticQuery, Link } from "gatsby"

import Title from "./Title"

const query = graphql`
  {
    allStrapiJobs(sort: { fields: strapiId, order: DESC }) {
      nodes {
        strapiId
        company
        date
        website
        description {
          id
          name
        }
        position
      }
    }
  }
`

const Jobs = () => {
  const data = useStaticQuery(query)
  const {
    allStrapiJobs: { nodes: jobs },
  } = data
  const [value, setValue] = useState(0)
  const { position, date, description, website } = jobs[value]

  return (
    <section className="section jobs">
      <Title title="experience" />
      <div className="jobs-center">
        <div className="btn-container">
          {jobs.map((item, index) => {
            return (
              <button
                key={item.strapiId}
                onClick={() => setValue(index)}
                className={`job-btn ${index === value && "active-btn"}`}
              >
                {item.company}
              </button>
            )
          })}
        </div>
        <article className="job-info">
          <h3>{position}</h3>
          <p className="job-date">{date}</p>

          {description.map((item, index) => {
            return (
              <div key={index} className="job-desc">
                <FaAngleDoubleRight className="job-icon"></FaAngleDoubleRight>
                <p>{item.name}</p>
              </div>
            )
          })}
          <a
            href={website}
            target="_blank"
            rel="noreferrer"
            className="social-link"
          >
            <h4>website</h4>
          </a>
        </article>
      </div>
      <Link to="/about" className="btn center-btn ">
        more info
      </Link>
    </section>
  )
}

export default Jobs
