import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import PropTypes from "prop-types"
import { FaLongArrowAltRight } from "react-icons/fa"

import SocialLinks from "../constants/socialLinks"

const query = graphql`
  {
    file(relativePath: { eq: "hero-img.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Hero = () => {
  const {
    file: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(query)
  return (
    <header className="hero">
      <div className="section-center hero-center">
        <article className="hero-info">
          <h1>
            i'm <span className="word-color">Amir</span> Rajabi
          </h1>
          <h4>Web & Mobile developer | Front End Developer</h4>
          <div className="underline"></div>
          <Link to="/contact" className="btn">
            contact me
          </Link>
          <SocialLinks />
          <p className="scroll-down">
            Scroll Down
            <span className="scroll-down-arrow">
              <FaLongArrowAltRight />
            </span>
          </p>
        </article>
        <Image fluid={fluid} className="hero-img" />
      </div>
    </header>
  )
}

Hero.propTypes = {
  siteTitle: PropTypes.string,
}

Hero.defaultProps = {
  siteTitle: ``,
}

export default Hero
