import React from "react"
import { FaCode, FaAndroid, FaApple } from "react-icons/fa"

export default [
  {
    id: 1,
    icon: <FaCode className="service-icon" />,
    title: "Web Developer",
    text: `Responsible for the coding, design, and layout of a website according to a company's specifications. As the role takes into consideration user experience and function, a certain level of both graphic design and computer programming.`,
  },
  {
    id: 2,
    icon: <FaApple className="service-icon" />,
    title: "iOS Developer",
    text: `Design and build applications for mobile devices running Apple's iOS operating software with React Native. Responsible for designing and coding the base application, ensuring the quality of the application.`,
  },
  {
    id: 3,
    icon: <FaAndroid className="service-icon" />,
    title: "Android Developer",
    text: `Design and build applications for mobile devices running Android operating software with React Native. Responsible for designing and coding the base application, ensuring the quality of the application.`,
  },
]
